import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

// Components
import EmailForm from "../forms/emailForm"

const PodcastFormSection = ({ heading, heading2, heading3, subheading, title }) => {
  return (
      <>
      <div className="flex flex-col">
        <div className="flex flex-row mb-8">
          {heading2 && 
            <h2 className="text-xl sm:text-4xl text-center max-w-[46%] sm:max-w-[40%] mx-auto font-medium mb-8">
              {heading2}
            </h2>
          }
          {heading3 && 
            <h2 className="text-xl sm:text-4xl text-center max-w-[46%] sm:max-w-[40%] mx-auto font-medium mb-8">
              {heading3}
            </h2>
          }
        </div>
        {heading && 
        <h2 className="text-3xl sm:text-5xl text-center max-w-[770px] mx-auto font-medium mb-8">
          {heading}
        </h2>
      }
      </div>
      {subheading && 
        <h3 className="text-base sm:text-2xl text-center mx-auto max-w-[700px]">
          {subheading}
        </h3>
      }
      <div id="join" className="flex mt-16 mb-32 flex-row items-center justify-center max-h-[559px]">
        <div className="
        hidden 2xl:flex 
        flex-[0_1_295px]
        ">
          <StaticImage className="h-[464.47px]" src="../../images/podcast-form-image-left.png" alt=""/>
        </div>
        <div className="flex md:flex-[0_0_675px] items-center justify-center rounded-lg bg-white">
          <EmailForm title={title} />
        </div>
        <div className="hidden 2xl:flex flex-[0_1_341px]">
          <StaticImage className="h-[464.47px]" src="../../images/podcast-form-image-right.png" alt=""/>
        </div>
      </div>
    </>
  )
}

export default PodcastFormSection
