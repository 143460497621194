import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';

// Static files
import Stage3top from "../../images/stage-3-image-top.svg"
import Stage3right from "../../images/stage-3-image-right.svg"
import Stage3bottom from "../../images/stage-3-image-bottom.svg"
import Stage2 from "../../images/stage-2-image.svg"
import Stage1 from "../../images/stage-1-image.svg"

const DevStages = ({ development }) => {
    return (
        <>
                <h2 className="text-3xl sm:text-5xl max-w-[1056px] mx-auto font-medium mb-10">
                    <ScrollAnimation animateIn="fadeIn" animateOnce>
                        {development?.heading}
                    </ScrollAnimation>
                </h2>
            <div className="flex flex-col max-w-[1056px] mx-auto">
                <div className="
                    flex flex-col
                    max-w-[814px]
                    my-10 p-10 sm:p-16 xl:pr-32
                    bg-white
                    rounded-lg
                    relative
                    z-10"
                >
                    <img alt="" src={Stage1} className="
                        w-[291.75px] h-[74.08px]
                        absolute
                        bottom-[-74.08px]
                        left-10
                        hidden xl:block
                        "
                    />
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-8"
                        >
                            Stage 1
                        </h2>
                    <h3 className="text-base mb-2 sm:text-2xl font-medium">{development?.stageone?.heading}</h3>
                    <p className="text-base mb-4 sm:text-xl">{development?.stageone?.textTop}</p>
                    <p className="text-base mb-4 sm:text-xl">{development?.stageone?.textMiddle}</p>
                    <p className="text-base sm:text-xl">{development?.stageone?.textBottom}</p>
                </div>
                <div className="
                    flex flex-col
                    max-w-[814px]
                    my-10 p-10 sm:p-16
                    bg-white
                    rounded-lg
                    self-end
                    relative
                    z-20"
                >
                    <img alt="" src={Stage2} className="
                        w-[294.93px] h-[347.64px]
                        absolute
                        right-10
                        top-[-347.64px]
                        -z-50
                        hidden xl:block"
                    />
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-8"
                        >
                            Stage 2
                        </h2>
                    <h3 className="text-base mb-2 sm:text-2xl font-medium">{development?.stagetwo?.heading}</h3>
                    <p className="text-base mb-4 sm:text-xl">{development?.stagetwo?.textTop}</p>
                    <p className="text-base sm:text-xl">{development?.stagetwo?.textBottom}</p>
                </div>
                <div className="
                    flex flex-col
                    max-w-[814px]
                    my-10 p-10 
                    sm:p-16 sm:pb-24
                    bg-white
                    rounded-lg
                    relative"
                >
                    <img alt="" src={Stage3top} className="
                        w-[191.45px] h-[375.75px]
                        absolute
                        top-[-375.75px]
                        left-24
                        hidden xl:block"
                    />
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-8"
                        >
                            Stage 3
                        </h2>
                    <h3 className="text-base mb-2 sm:text-2xl font-medium">
                        {development?.stagethree?.heading}
                    </h3>
                    <p className="text-base mb-4 sm:text-xl">
                        {development?.stagethree?.textTopOne}
                    </p>
                    <p className="text-base mb-4 sm:text-xl">
                        {development?.stagethree?.textTopTwo}
                    </p>
                    <p className="text-base mb-6 sm:text-xl">
                        {development?.stagethree?.textMiddle}
                    </p>
                    <GatsbyImage
                        image={development?.stagethree?.image?.childImageSharp?.gatsbyImageData}
                        alt={development?.stagethree?.imageAlt}
                        className={"max-w-[268px] mx-auto mb-6"}
                    />
                    <p className="text-base sm:text-xl">
                        {development?.stagethree?.textBottom}
                    </p>
                    <img alt="" src={Stage3right} className="
                        w-[231px] h-[338px]
                        absolute
                        right-[-231px]
                        top-56
                        hidden xl:block"
                    />
                    <img alt="" src={Stage3bottom} className="
                        w-[324.75px] h-[310.5px]
                        absolute
                        bottom-10
                        right-[-324.75px]
                        hidden xl:block"
                    />
                </div>
            </div>
        </>
    )
}

export default DevStages
