import * as React from "react"

const Wrapper = ({ children }) => {
  return (
    <div className="max-w-screen-2xl px-6 sm:px-16 mx-auto">
        {children}
    </div>
  )
}

export const WrapperDesktopOnly = ({ children }) => {
  return (
    <div className="w-full sm:max-w-screen-2xl px-2 sm:px-16 mx-auto">
      {children}
    </div>
  )
}

export default Wrapper


