import * as React from "react"

// Files
import ImageLeft from "../../images/curriculum-about-image-left.svg"
import ImageRight from "../../images/curriculum-about-image-right.svg"

const AboutBox = ({ about }) => {
    return (
        <div className="
            flex flex-col 
            mx-auto my-20 max-w-[1050px] 
            p-10 md:py-20 md:pb-24 md:px-40
            bg-blue-darker text-white
            rounded-lg
            relative"
        >
            <img 
                src={ImageLeft} alt=""
                className="
                    absolute 
                    top-0 
                    left-[-115.33px] 
                    h-[341.46px] 
                    w-[115.33]
                    hidden xl:block"
            />
                <h2 className="text-3xl sm:text-5xl font-medium text-center mb-8">
                    {about?.heading}
                </h2>
            <p className="text-base sm:text-xl">
                {about?.text}
            </p>
            <img 
                src={ImageRight} alt=""
                className="
                    absolute 
                    bottom-0 
                    right-[-111.42px] 
                    h-[356.54px] 
                    w-[111.42px]
                    hidden xl:block"
            />
        </div>
    )
}

export default AboutBox
