import * as React from "react"

const Phases = ({ phases }) => {
    return (
        <div className="
            flex flex-col
            px-6 
            my-0
            mx-auto
            max-w-[950px]"
        >
            {phases.map((item, index) => {
                return (
                    <div key={index} className="border-b border-neutral-light last:border-0 py-10">
                            <h3 className="text-xl sm:text-3xl font-medium">{item.heading}</h3>
                        <p className="text-base sm:text-xl">{item.text}</p>
                    </div>
                )
            })}
        </div>
    )   
}

export default Phases
