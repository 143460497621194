import * as React from "react"
import ScrollAnimation from 'react-animate-on-scroll';

const Outputs = ({ outputs }) => {
    return (
        <div className="w-100 bg-blue-darker py-24 text-white mt-20">
                <h2 className="text-3xl sm:text-5xl text-center font-medium">
                <ScrollAnimation animateIn="fadeIn" animateOnce>
                    {outputs.heading}
                </ScrollAnimation>
                </h2>
            <div className="
                flex flex-row 
                justify-center
                mt-14 mx-auto
                max-w-[1056px]  
                px-6 md:px-0
                "
            >
                <ul className="
                    list-none 
                    flex flex-1 
                    flex-col 
                    max-w-[430px]
                    border-r border-white
                    pr-6 sm:pr-14"
                >
                    {outputs.outputone.map((item, index) => {
                        return (
                        <li key={index} className="mb-8">
                            <h3 className="text-xl sm:text-4xl font-medium">
                                {item.title}
                            </h3>
                            <p className="text-base sm:text-xl">
                                {item.subtitle}
                            </p>
                        </li>
                        )
                    })}
                    
                </ul>
                <ul className="
                    list-none 
                    flex flex-1 
                    flex-col 
                    max-w-[460px]
                    pl-6 sm:pl-14"
                >
                    {outputs.outputtwo.map((item, index) => {
                        return (
                        <li key={index} className="mb-8">
                            <h3 className="text-xl sm:text-4xl font-medium">
                                {item.title}
                            </h3>
                            <p className="text-base sm:text-xl">
                                {item.subtitle}
                            </p>
                        </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )   
}

export default Outputs
