import * as React from "react"
import MarkdownContent from "./mdxhtmlConverter"

// Static files
import Team1Bottom from "../../images/team-1-bottom.svg"
import Team1TopLeft from "../../images/team-1-top-left.svg"
import Team1TopRight from "../../images/team-1-top-right.svg"
import Team2Bottom from "../../images/team-2-bottom.svg"
import Team3Top from "../../images/team-3-top.svg"
import Team4BottomLeft from "../../images/team-4-bottom-left.svg"
import Team4BottomRight from "../../images/team-4-bottom-right.svg"
import Team4TopLeft from "../../images/team-4-top-left.svg"
import Team4TopRight from "../../images/team-4-top-right.svg"
import Team6BottomLeft from "../../images/team-6-bottom-left.svg"
import Team6BottomRight from "../../images/team-6-bottom-right.svg"
import Team6TopLeft from "../../images/team-6-top-left.svg"
import Team6TopRight from "../../images/team-6-top-right.svg"

const Services = ({ services }) => {
    return (
        <>
            <div className="flex flex-col gap-y-10 my-40">
                <div className="
                    flex flex-col
                    w-full
                    p-10 sm:p-16 
                    bg-white
                    rounded-lg
                    relative
                    z-10"
                >
                    <img 
                        src={Team1TopLeft}
                        alt=""
                        className="
                        w-[411px] h-[87px]
                        top-[-87px]
                        left-16
                        absolute hidden xl:block"
                    />
                    <img 
                        src={Team1TopRight} 
                        alt=""
                        className="
                        w-[268px] h-[109px]
                        top-[-109px]
                        right-14
                        absolute hidden xl:block"
                    />
                    <img
                        src={Team1Bottom} 
                        alt=""
                        className="
                        w-[203px] h-[42px]
                        bottom-[-42px]
                        left-16
                        absolute hidden xl:block"
                    />
                    
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-4"
                        >
                            {services[0].heading}
                        </h2>
                    
                    <MarkdownContent 
                        content={services[0].body} 
                        className="prose prose-base sm:prose-xl max-w-none text-dark" 
                    />
                </div>
                <div className="
                    flex flex-col
                    w-full lg:w-1/2
                    p-10 sm:p-16 
                    bg-white
                    rounded-lg
                    relative
                    z-10"
                >
                    <img
                        src={Team2Bottom} 
                        alt=""
                        className="
                        w-[203px] h-[87px]
                        bottom-[-87px]
                        left-16
                        absolute hidden xl:block"
                    />
                    
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-4"
                        >
                            {services[1].heading}
                        </h2>
                    
                    <MarkdownContent 
                        content={services[1].body} 
                        className="prose prose-base sm:prose-xl max-w-none text-dark" 
                    />                
                </div>
                <div className="
                    flex flex-col
                    w-full lg:w-1/2
                    self-end
                    p-10 sm:p-16 
                    bg-white
                    rounded-lg
                    relative
                    xl:-mt-8
                    z-10"
                >
                    <img
                        src={Team3Top} 
                        alt=""
                        className="
                        w-[379.11px] h-[191.07px]
                        top-[-191.07px]
                        right-20
                        absolute hidden xl:block"
                    />
                    
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-4"
                        >
                            {services[2].heading}
                        </h2>
                    
                    <MarkdownContent 
                        content={services[2].body} 
                        className="prose prose-base sm:prose-xl max-w-none text-dark" 
                    />                  
                </div>
                <div className="
                    flex flex-col
                    w-full
                    p-10 sm:p-16 
                    bg-white
                    rounded-lg
                    relative
                    z-20"
                >
                    <img
                        src={Team4TopLeft} 
                        alt=""
                        className="
                        w-[407.89px] h-[163px]
                        top-[-163px]
                        left-16
                        absolute hidden xl:block"
                    />
                    <img
                        src={Team4TopRight} 
                        alt=""
                        className="
                        w-[203px] h-[87px]
                        top-[-87px]
                        left-[600px]
                        absolute hidden xl:block"
                    />
                    <img
                        src={Team4BottomRight} 
                        alt=""
                        className="
                        w-[203px] h-[129px]
                        bottom-[-129px]
                        left-[600px]
                        absolute hidden xl:block"
                    />
                    <img
                        src={Team4BottomLeft} 
                        alt=""
                        className="
                        w-[203px] h-[42px]
                        bottom-[-42px]
                        left-16
                        absolute hidden xl:block"
                    />
                    
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-4"
                        >
                            {services[3].heading}
                        </h2>
                    
                    <MarkdownContent 
                        content={services[3].body} 
                        className="prose prose-base sm:prose-xl max-w-none text-dark" 
                    />                  
                </div>
                <div className="
                    flex flex-col
                    w-full
                    p-10 sm:p-16 
                    bg-white
                    rounded-lg
                    relative
                    z-10"
                >
                    
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-4"
                        >
                            {services[4].heading}
                        </h2>
                    
                    <MarkdownContent 
                        content={services[4].body} 
                        className="prose prose-base sm:prose-xl max-w-none text-dark" 
                    />                  
                    </div>
                <div className="
                    flex flex-col
                    w-full
                    p-10 sm:p-16 
                    bg-white
                    rounded-lg
                    relative
                    mt-5
                    z-10"
                >
                    <img
                        src={Team6TopLeft} 
                        alt=""
                        className="
                        w-[210px] h-[106px]
                        top-[-106px]
                        left-28
                        absolute hidden xl:block"
                    />
                    <img
                        src={Team6BottomLeft} 
                        alt=""
                        className="
                        w-[210px] h-[68px]
                        bottom-[-68px]
                        left-28
                        absolute hidden xl:block"
                    />   
                    <img
                        src={Team6TopRight} 
                        alt=""
                        className="
                        w-[203px] h-[60px]
                        top-[-60px]
                        right-28
                        absolute hidden xl:block"
                    />   
                    <img
                        src={Team6BottomRight} 
                        alt=""
                        className="
                        w-[139px] h-[31px]
                        bottom-[-31px]
                        right-36
                        absolute hidden xl:block"
                    />               
                    
                        <h2 className="
                            text-2xl sm:text-4xl 
                            font-medium
                            mb-4"
                        >
                            {services[5].heading}
                        </h2>
                    
                    <MarkdownContent 
                        content={services[5].body} 
                        className="prose prose-base sm:prose-xl max-w-none text-dark" 
                    />                  
                    </div>
            </div>
        </>
    )
}

export default Services
