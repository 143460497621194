import * as React from "react"
import { graphql } from "gatsby"

// Custom components
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Story from "../components/story/story"
import Wrapper from "../components/layout/wrapper"
import AboutBox from "../components/curriculum/aboutBox"
import DevStages from "../components/curriculum/devStages"
import Outputs from "../components/curriculum/outputs"
import Services from "../components/team/services"
import CaseStudy from "../components/team/caseStudy"
import PodcastFormSection from "../components/podcast/podcastFormSection"

// Static files
import Line from "../images/line-curriculum.svg"
import TeamLine from "../images/line-team.svg"

const LineData = (
  [Line, 'top-[800px]', 'left-0', '', '']
)
const TeamLineData = (
  [TeamLine, 'top-[5050px]', 'left-0', '', '']
)

export const query = graphql`
query {
  allMdx(filter: {frontmatter: {templateKey: {eq: "curriculum"}}}) {
    nodes {
      frontmatter {
        metaDescription
        metaTitle
        templateKey
        intro {
          heading
          subheading
          text
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        about {
            heading
            text
        }
        development {
            heading
            stageone {
                heading
                textTop
                textMiddle
                textBottom
            }
            stagetwo {
                heading
                textTop
                textBottom
            }
            stagethree {
                heading
                textTopOne
                textTopTwo
                textMiddle
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                imageAlt
                textBottom
            }
        }
        outputs {
            heading
            outputone {
                title
                subtitle
            }
            outputtwo {
                title
                subtitle
            }
        }
      }
    }
  }
  team: allMdx(filter: {frontmatter: {templateKey: {eq: "team"}}}) {
    nodes {
      frontmatter {
        metaDescription
        metaTitle
        templateKey
        intro {
          heading
          text
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        services {
          heading
          body
        }
        casestudy {
          heading
          body
          phases {
            heading
            text
          }
        }
      }
    }
  }
}
` 
const IndexPage = ({ data }) => {
  const allData = data?.allMdx?.nodes[0]
  const allIntro = allData?.frontmatter?.intro 

  const allTeamData = data?.team.nodes[0]
  const allTeamIntro = allTeamData?.frontmatter?.intro 

  const CurriculumStories = () => (
    allIntro.map((item, index) => {
      return (
          <Story
            key={index}
            heading={item.heading}
            subheading={item.subheading}
            text={item.text}
            image={item.image}
            imageAlt={item.imageAlt}
            reverse={index % 2 ? false : true}
          />
      )
    })
  )

  const TeamStories = () => (
    allTeamIntro.map((item, index) => {
      return (
          <Story
            key={index}
            heading={item.heading}
            text={item.text}
            image={item.image}
            imageAlt={item.imageAlt}
            reverse={index % 2 ? false : true}
            imgHero
          />
      )
    })
  )
  return (
    <Layout line={LineData} lineTeam={TeamLineData} fullWidth>
      <Seo 
        title={allData?.frontmatter?.metaTitle}
        description={allData?.frontmatter?.metaDescription}
      />
        <Wrapper>
          <div className="flex flex-col gap-y-32 my-16">
            <CurriculumStories />
          </div>
          <AboutBox about={allData?.frontmatter?.about} />
          <DevStages development={allData?.frontmatter?.development} />
        </Wrapper>
        <Outputs outputs={allData?.frontmatter?.outputs} />    
        <Wrapper>
          <div className="flex flex-col gap-y-32 my-16">
            <TeamStories/>
          </div>
          <Services services={allTeamData?.frontmatter?.services} />
        </Wrapper>
        <CaseStudy 
          casestudy={allTeamData?.frontmatter?.casestudy}
          phases={allTeamData?.frontmatter?.casestudy?.phases}
        />
        <Wrapper>
          <PodcastFormSection 
            heading="Wherever you are on your journey, contact us for expert advice and support." 
            title="Message from Curriculum form"
          />
        </Wrapper>
    </Layout>
  )
}

export default IndexPage
