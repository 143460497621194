import * as React from "react"

// Components
import MarkdownContent from "./mdxhtmlConverter"
import Phases from "./phases"

// Static files
import CaseTopLeft from "../../images/team-case-top-left.svg"
import CaseTopRight from "../../images/team-case-top-right.svg"
import CaseBottomLeft from "../../images/team-case-bottom-left.svg"
import CaseBottomRight from "../../images/team-case-bottom-right.svg"

const CaseStudy = ({ casestudy, phases }) => {
    return (
        <div className="w-100 bg-blue-darker py-24 px-6 text-white my-20 relative">
            <img 
                src={CaseTopLeft}
                alt=""
                className="
                w-[236px] h-[178px]
                -top-1
                left-0
                absolute hidden xl:block"
            />
            <img 
                src={CaseTopRight}
                alt=""
                className="
                w-[178px] h-[236px]
                -top-1
                right-0
                absolute hidden xl:block"
            />
            <img 
                src={CaseBottomLeft}
                alt=""
                className="
                w-[178px] h-[236px]
                bottom-0
                left-0
                absolute hidden xl:block"
            />
            <img 
                src={CaseBottomRight}
                alt=""
                className="
                w-[236px] h-[178px]
                right-0
                -bottom-1
                absolute hidden xl:block"
            />
                <h2 className="text-3xl mb-6 sm:text-5xl text-center font-medium">
                    {casestudy.heading}
                </h2>
            <MarkdownContent 
                content={casestudy.body} 
                className="
                prose prose-base 
                sm:prose-xl 
                max-w-[950px]
                px-6 
                mx-auto 
                text-white
                prose-a:text-white
                prose-a:hover:text-white" 
            />
            <Phases phases={phases} />
        </div>
    )   
}

export default CaseStudy
